import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2019-05-16T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/musings/primer-resuability.png",
        "alt": "Considering theme resuability across theme, layout, components, and utilities"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      